/** layous 样式*/
.layout-header {
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
}
.site-layout-content {
  /*padding: 0 200px;*/
  min-height: 280px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  position: fixed;
  top: 64px;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
}
.logo {
  float: left;
  width: 120px;
  height: 100%;
  margin: auto;
  background: url("../Source/image/home-icon.png");
}
/** content样式 */
.canvas {
  position: absolute;
  display: block;
  background: #111;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  height: 70px;
  z-index: 100;
  background: rgba(0, 0, 0, 1);
  color: #1890ff;
  /* color: white; */
}
/*loading样式*/
.loading-container{
  width: 100%;
  height: 100%;
  --ll:50px;
  --cl:200px;
  --sl:calc(var(--cl) - var(--ll));
  --hsl:calc(var(--sl) / 2);
}
.loading-container .loading{
  width: var(--cl);
  height: var(--cl);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.loading-container .left,.loading-container .right,.loading-container .top,.loading-container .bottom{
  width: var(--ll);
  height:var(--ll);
  background: red;
  position: absolute;
  animation: rotateItem 3s linear infinite;
  border-radius: 10px;
}
.loading-container .top{
  transform: translate(var(--hsl),0);
  animation-delay: -0.75s;
}
.loading-container .right{
  transform: translate(var(--sl),var(--hsl));
  animation-delay: -1.5s;
}
.loading-container .bottom{
  transform: translate(var(--hsl),var(--sl));
  animation-delay: -2.25s;
}
@keyframes rotateItem {
  0%{
    transform: translate(0,var(--hsl)) rotate(0deg);
    background: linear-gradient(to right, #79c3fc,#6ecaff,#6eabff,#6eabff);
  }
  25%{
    transform: translate(var(--hsl),0) rotate(90deg);
    background: linear-gradient(to right,#6ecaff,#6eabff,#6eabff,#79c3fc);
  }
  50%{
    transform: translate(var(--sl),var(--hsl)) rotate(180deg);
    background: linear-gradient(to right, #6eabff,#6eabff,#79c3fc,#6ecaff );
  }
  75%{
    transform: translate(var(--hsl),var(--sl)) rotate(270deg);
    background: linear-gradient(to right,#6eabff,#79c3fc,#6ecaff,#6eabff);
  }
  100%{
    transform: translate(0,var(--hsl)) rotate(360deg);
    background: linear-gradient(to right,#79c3fc,#6ecaff,#6eabff,#6eabff);
  }
}
